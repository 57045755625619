import { createMuiTheme, Theme } from '@material-ui/core/styles';
import Colors from './Colors';

type IOptions = {
  palette: { type: 'light' | 'dark' };
};
const defaultOptions: IOptions = {
  palette: {
    type: 'light'
  }
};
export const createTheme = (options = defaultOptions): Theme => {
  return createMuiTheme({
    palette: {
      primary: {
        main: Colors.Primary,
        contrastText: '#fff'
      },
      secondary: {
        main: Colors.Primary
      },
      type: options.palette.type
    },
    overrides: {
      MuiTooltip: {
        tooltip: {
          backgroundColor: 'black',
          fontSize: '0.7em'
        }
      },
      MuiListItem: {
        root: {
          '&.Mui-selected': {
            backgroundColor: Colors.Primary + 'CC' // Opacity 80%
          },
          '&:hover': {
            backgroundColor: Colors.Primary + '66 !important' // Opacity 40%
          }
        }
      }
    },
    typography: {
      button: {
        textTransform: 'none'
      }
    }
  });
};
