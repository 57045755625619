import { ConfigActionType } from '../actionTypes';
import { IConfigState } from '../stateTypes';
import { INIT_CONFIG } from './../actionTypes';

const initialState: IConfigState = {
  baseUrl: '',
  apiBaseUrl: '',
  clientBaseUrl:'',
  apimKey: '',
  ProjectTitle: '',
  GroupByProp: '',
  DatasheetNumberOfLines: 0,
  InstrumentationKey: '',
  PbiUrl: '',
  GFontsUrl: '',
  ElementNamespaceUrl: '',
  ConstructionPortalUrl: '',
  markupLabelLimit: 0,
  multipleMarkupUpperLimit:0,
  forceMemoryOptimize:0,
  entityUrlSizeLimit:0,
  commentFileMaxSize: 0,
  commentMaxFilesAllowed: 0,
  commentAllowedFileFormat: [],
  commentAllowedFileMimeType: {},
  commentAllowedFileContent: [],
  workpackageFileMaxSize: 0,
  workpackageMaxFilesAllowed: 0,
  workpackageAllowedFileFormat: [],
  workpackageAllowedFileMimeType: {},
  workpackageAllowedFileContent: [],
  uploadDocumentAllowedFileContent: [],
  uploadDocumentAllowedFileFormat: [],
  uploadDocumentAllowedFileMimeType: {},
  uploadDocumentMaxSize: 0,
  uploadDocumentMaxFilesAllowed: 0,
  statusVisFileMaxSize: 0,
  statusVisMaxFilesAllowed: 0,
  statusVisAllowedFileFormat: [],
  statusVisAllowedFileMimeType: {},
  statusVisAllowedFileContent: [],
  variousFilesAllowedFileContent: [],
  variousFilesAllowedFileFormat: [],
  variousFilesAllowedFileMimeType: {},
  variousFilesFileMaxSize: 0,
  variousFilesMaxFilesAllowed: 0,
  //uploadDocumentAllowedFileContent: [],
 // uploadDocumentAllowedFileFormat: [],
  //uploadDocumentAllowedFileMimeType: {},
  //uploadDocumentMaxSize: 0,
  //uploadDocumentMaxFilesAllowed: 0,
  supportEmail: '',
  esv2: [],
  esv2SignalRUrls: '',
  cspDefaultSrc: '',
  cspScriptSrc: '',
  cspStyleSrc: '',
  cspFontSrc: '',
  cspMediaSrc: '',
  cspFrameAncestors: '',
  cspImgSrc: '',
  env: '',
  isEasyStructure : '',
  domain:'',
  marketingTopics: ''
};

export function configReducer(
  state = initialState,
  action: ConfigActionType
): IConfigState {
  if (action.type === INIT_CONFIG) {
    return Object.assign({}, state, {
      ...action.payload
    });
  }
  return state;
}
