const Colors = {
  Red: '#e84242',
  Purple: '#513073',
  Blue: '#0070ef',
  DarkBlue: '#1D79B7',
  ForgeBlue: '#00bfff',
  Green: '#80c7a0',
  Orange: '#ee7766',
  DarkRed: '#B92D00',
  LightGrey: '#ECECEC',
  Grey: '#767676',
  White: '#FFFFFF',
  Primary: '',
  Secondary: ''
};
Colors.Primary = Colors.Blue;
Colors.Secondary = Colors.Green;

export default Colors;
