import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  wrap: {
    backgroundColor: '#efefef',
    position: 'relative',
    width: '100%',
    height: '100%',
    overflow: 'hidden'
  },
  splashScreenWrapper: {
    width: '100%',
    height: '94vh',
    textAlign: 'center',
    position: 'absolute',
    top: '20%'
  },
  paper: {
    borderRadius: '20px',
    boxShadow: '10px 10px 10px rgb(0 0 0 / 15%)',
    margin: '0 auto',
    display: 'inline-block',
    minWidth: '310px'
  },
  splashScreenLogo: {
    height: '220px',
    margin: '0 auto',
    padding: '35px 70px'
  },
  copyrightLabel: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    margin: '20px',
    color: 'black',
    textAlign: 'center'
  }
});

export default useStyles;
