import React, { Component, Fragment } from 'react';
import { withAITracking } from '@microsoft/applicationinsights-react-js';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { ai } from '../services/TelemetryService';

type IPathParamsType = {
}
interface IStateProps {
    history: History
}

interface IState {
    initialized: boolean
}
interface IProps {
    instrumentationKey: string
}
type PropsType = RouteComponentProps<IPathParamsType> & IStateProps & IProps;

/**
 * This Component provides telemetry with Azure App Insights
 *
 * NOTE: the package '@microsoft/applicationinsights-react-js' has a HOC withAITracking that requires this to be a Class Component rather than a Functional Component
 */
class TelemetryProvider extends Component<PropsType, IState> {
    state = {
        initialized: false
    };

    componentDidMount() {
        const { history } = this.props;
        const { initialized } = this.state;
        const AppInsightsInstrumentationKey = this.props.instrumentationKey; // PUT YOUR KEY HERE
        if (!Boolean(initialized) && Boolean(AppInsightsInstrumentationKey) && Boolean(history)) {
            ai.initialize(AppInsightsInstrumentationKey, history);
            this.setState({ initialized: true });
        }
    }

    render() {
        const { children } = this.props;
        return (
            <Fragment>
                {children}
            </Fragment>
        );
    }
}

export default withRouter(withAITracking(ai.reactPlugin!, TelemetryProvider));
