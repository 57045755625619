
import { WPActionType, SELECT_ISOLATE_WP ,SET_PREV_WP} from '../actionTypes';
import { IWPState } from '../stateTypes';

const initialState: IWPState = {
}

export function workpackageReducer(
    state = initialState,
    action: WPActionType
): IWPState {
    if (action.type === SELECT_ISOLATE_WP) {
        return Object.assign({}, state, {
            workpackage: action.payload.workpackage,
            workpackageAction: action.payload.workpackageAction
        });
    }
    if (action.type === SET_PREV_WP) {
        return Object.assign({}, state, {
            prevWorkpackage: action.payload.workpackage,
            prevWorkpackageAction: action.payload.workpackageAction
        });
    }
    return state;
}

