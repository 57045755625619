import React from 'react';
import ReactMarkdown from 'react-markdown';
import ApplicationService from '../services/ApplicationService';
 import { Scrollbars } from 'react-custom-scrollbars-2';

import './ChangelogComponent.css';

type IState = {
    changelog?: string;
}
class Changelog extends React.Component<{}, IState>{
    constructor(props: any) {
        super(props);
        this.state = {};
    }
    async componentDidMount() {
        const changelog = await ApplicationService.getChangelog();
        this.setState({ changelog });
    }
    render() {
        const { changelog } = this.state;
        if (!changelog) return <></>;
        return (
            <div style={{ color: 'black', height: '100%', paddingLeft: '10vw', paddingRight: '10vw' }}>
                { <Scrollbars style={{ height: "100%" }}>
                    <ReactMarkdown>{changelog}</ReactMarkdown>
                </Scrollbars> }
            </div>
        );
    }

}

export default Changelog;
