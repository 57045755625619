import React, {
  FunctionComponent,
  useEffect,
  useState,
  useCallback
} from 'react';
import './App.css';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
// import LoginComponent from './components/LoginComponent';
import ApplicationService from './services/ApplicationService';
import { Provider } from 'react-redux';
import store from './redux/store';
import { initConfig } from './redux/actions';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import InterceptorAxios from './utils/InterceptorAxios';
import 'react-datasheet/lib/react-datasheet.css';
import Changelog from './components/ChangelogComponent';
import { ThemeProvider, Theme } from '@material-ui/core/styles';
import 'typeface-roboto';
import './utils/i18n';
import { createTheme } from './utils/Theme';
import TelemetryProvider from './utils/TelemetryProvider';
import { IAppConfig } from './data-models/dataModels';
import withClearCache from './ClearCache';
import PrivateRoute from './components/RouterComponent';

import {
  ProjectComponentPage,
  HomeComponentPage,
  ConstructionPortalPage,
  UnAuthorizedComponentPage,
  SnapshotImageComponentPage,
  ProjectComponentPageForExternalUser
} from './components/Routes/index';


InterceptorAxios.configure();

const theme: Theme = createTheme();

const MainApp: FunctionComponent = () => {
  const [isReady, setIsReady] = useState(false);
  const [config, setConfig] = useState<IAppConfig>();

  const getConfig = useCallback(async () => {
    const config = await ApplicationService.getAppConfig();
    store.dispatch(initConfig(config!));
    setConfig(config);
    setIsReady(true);
  }, [setConfig]);

  useEffect(() => {
    sessionStorage.removeItem("panel-reposition-ids");
  },[]);

  useEffect(() => {
    try {
      getConfig();
    } catch (e) {
      console.error('config not load');
    }
  }, [getConfig]);

  // Set up a CSP meta tag when the component mounts
  useEffect(() => {
    // Define your CSP content
    if (config) {
      const cspContent = `
        default-src ${config.cspDefaultSrc};
        script-src ${config.cspScriptSrc};
        style-src ${config.cspStyleSrc};
        font-src ${config.cspFontSrc};
        media-src ${config.cspMediaSrc};
        frame-ancestors ${config.cspFrameAncestors};
        img-src ${config.cspImgSrc};
      `;

      // Create a new CSP meta tag
      const cspMeta = document.createElement('meta');
      cspMeta.httpEquiv = 'Content-Security-Policy';
      cspMeta.content = cspContent.trim();

      // Append the meta tag to the document's head
      document.head.appendChild(cspMeta);

      // Cleanup function to remove the meta tag when the component unmounts
      return () => {
        document.head.removeChild(cspMeta);
      };
    }
  }, [config]);

  return (
    <Provider store={store}>
      <BrowserRouter>
        {isReady && (
          <TelemetryProvider instrumentationKey={config!.InstrumentationKey}>
            <ThemeProvider theme={theme}>
              <Switch>
                {/* <Route path='/login' component={LoginComponent} /> */}
                {/* <PrivateRoute component={HomeComponent} path='/viewer' /> */}
                <PrivateRoute
                  component={HomeComponentPage}
                  path='/:projectName/viewer'
                />
                <PrivateRoute component={ProjectComponentPage} path='/home' />
                <Route path='/unAuthorized' component={UnAuthorizedComponentPage} />
                <Route path='/changelog' component={Changelog} />
                <PrivateRoute component={ConstructionPortalPage} path='/:projectName/portal' />
                <PrivateRoute component={ProjectComponentPageForExternalUser} path='/:projectName/widget/:email' />
                <PrivateRoute path='/:projectName/snapshot/:snapshot' component={SnapshotImageComponentPage} />
                {/* <PrivateRoute path='/'><Redirect to='/viewer'/></PrivateRoute> */}
                <PrivateRoute path='/'>
                  <Redirect to='/home' />
                </PrivateRoute>
              </Switch>
            </ThemeProvider>
          </TelemetryProvider>
        )}
      </BrowserRouter>
      <ToastContainer />
    </Provider>
    
  );
};

const ClearCacheComponent = withClearCache(MainApp);

const App: FunctionComponent = () => {
  return <ClearCacheComponent />;
};

export default App;
