import { PermissionActionType } from '../actionTypes';
import { IPermissionsState } from '../stateTypes';
import { INIT_PERMISSIONS } from './../actionTypes';

const initialState: IPermissionsState = {
  permissions: [],
  username: ''
};

export function rbacReducer(
  state = initialState,
  action: PermissionActionType
): IPermissionsState {
  if (action.type === INIT_PERMISSIONS) {
    return Object.assign({}, state, {
      permissions: action.payload.permissions,
      username: action.payload.username
    });
  }
  return state;
}
