import React, { useEffect } from 'react';
import packageJson from '../package.json';
import moment from 'moment';

const CACHE_NAME = 'ep3d';

const buildDateGreaterThan = (latestDate: any, currentDate: any) => {
  const momLatestDateTime = moment(latestDate);
  const momCurrentDateTime = moment(currentDate);

  if (momLatestDateTime.isAfter(momCurrentDateTime)) {
    return true;
  } else {
    return false;
  }
};

function withClearCache(Component: any) {
  function ClearCacheComponent(props: any) {
    useEffect(() => {
      fetch('/meta.json', { headers: { 'Cache-Control': 'no-cache' } })
        .then((response) => response.json())
        .then((meta) => {
          const latestVersionDate = meta.buildDate;
          const currentVersionDate = packageJson.buildDate;

          const shouldForceRefresh = buildDateGreaterThan(
            latestVersionDate,
            currentVersionDate
          );
          if (shouldForceRefresh) {
            refreshCacheAndReload();
          }
        });
    }, []);

    const refreshCacheAndReload = () => {
      if (caches) {
        // Service worker cache should be cleared with caches.delete()
        caches.open(CACHE_NAME).then((cache) => {
          cache.keys().then((requests) => {
            for (const request of requests) {
              if (
                !request.url.includes('/api/plants') &&
                !request.url.includes('viewable')
              ) {
                cache.delete(request);
              }
            }
          });
        });
      }
    };

    return (
      <React.Fragment>
        <Component {...props} />
      </React.Fragment>
    );
  }

  return ClearCacheComponent;
}

export default withClearCache;
