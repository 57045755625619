
import {
    DRAG, DROP, FINISH_DND, DndActionType
} from '../actionTypes';
import { IDndState } from '../stateTypes';

const initialState: IDndState = {
    isDragging: false
}

export function dndReducer(
    state = initialState,
    action: DndActionType
): IDndState {
    switch (action.type) {
        case DRAG:
            return Object.assign({}, state, {
                isDragging: action.payload.isDragging,
                item: action.payload.item,
                dragType: action.payload.dragType,
                dropStatus: null
            });
        case DROP:
            return Object.assign({}, state, {
                isDragging: action.payload.isDragging
            });
        case FINISH_DND:
            return Object.assign({}, state, {
                dropStatus: action.payload.dropStatus
            });
        default:
            return state;
    }
}

