import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import axios from 'axios';

//create axios interceptor to customize requests
axios.interceptors.request.use((request) => {
  let pathname = window.location.pathname;
  //pass project name only if not present
  if (!request.headers.projectName) {
    if (pathname === '/') {
      request.headers.projectName = 'home';
    } else {
      let path = pathname.split('/')[1];
      request.headers.projectName = path;
    }
  }
  return request;
});

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
