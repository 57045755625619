import axios, { AxiosResponse, InternalAxiosRequestConfig, CancelTokenSource, CancelToken} from 'axios';
import store from '../redux/store';

export default class InterceptorAxios {
  private static  source:CancelTokenSource = axios.CancelToken.source();
  private static cancelToken:CancelToken = InterceptorAxios.source.token;

  public static configure = () => {
    axios.interceptors.response.use(
      (response: AxiosResponse) => {
        // Any status code that lie within the range of 2xx cause this function to trigger
        // Do something with response data
        return response;
      },
      function (error) {
        // Any status codes that falls outside the range of 2xx cause this function to trigger
        // Do something with response error
        if (error.response && error.response.status === 401) {
          const {
            config: { apiBaseUrl }
          } = store.getState();
          window.location.replace(`${apiBaseUrl}/login`);
        }
        return Promise.reject(error);
      }
    );
        axios.interceptors.request.use(
      (request: InternalAxiosRequestConfig) => {
        // Add the CancelToken to every request
        request.cancelToken = InterceptorAxios.cancelToken;

        const { config: { baseUrl, apimKey, env } } = store.getState();
        if (request.url?.startsWith(baseUrl)) {
          request.headers['Ocp-Apim-Subscription-Key'] = apimKey;
          request.headers['Environment'] = env;
        }

        return request;
      },
      err => {
        const error = new Error(err);
        return Promise.reject(error);
      }
    );
    InterceptorAxios.configCancelToken();
  };
  public static configCancelToken = () => {
    axios.interceptors.request.use(
      (request : InternalAxiosRequestConfig) => {
          request.cancelToken = InterceptorAxios.cancelToken;
          return request
      },
      err => {
          return Promise.reject(err)
      }
    );
  }
  public static cancelRequest = () => {
    if(InterceptorAxios.source){
       InterceptorAxios.source.cancel('Request canceled!');
       InterceptorAxios.source = axios.CancelToken.source();
       InterceptorAxios.cancelToken = InterceptorAxios.source.token;
       InterceptorAxios.configCancelToken();
       return
    }
  }
}
