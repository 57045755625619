import React, { lazy,Suspense } from "react";
import {isMobile, isTablet} from 'react-device-detect';
import { SplashScreen } from '../../utils/loadingComponents/SplashScreen';
import { LoadProjectSkeleton } from '../../utils/loadingComponents/LoadSkeleton';

import { useMediaQuery } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { setIsMobile } from "../../redux/actions";
import store from "../../redux/store";

const UnAuthorizedComponent = lazy(() => import('./UnAuthorized/UnAuthorizedComponent'));
const SnapshotImageComponent = lazy(() => import('./SnapshotImage/SnapshotImageComponent'));
const ConstructionPortal = lazy(() => import('./Portal/ConstructionPortal'));
const ProjectComponent = lazy(() => import('./Home/ProjectComponent'));
const WidgetForExternalUser = lazy(() => import('./Widget/WidgetForExternalUser'));

const HomeComponent = lazy(() => import('./Viewer/HomeComponent'));


const ProjectComponentPage = () => (
  <Suspense fallback={<SplashScreen/>}>
    <ProjectComponent />
  </Suspense>
);
const HomeComponentPage = () => {
  const theme = useTheme();
  const isExternalUser : boolean = window.location.pathname.split('/')[2] === 'widget' ? true : false;
  const lessThanXSmall = useMediaQuery(theme.breakpoints.down("xs"));
  const isMobileDevice = useMediaQuery(theme.breakpoints.down('md'));
  if(isMobile || isTablet || isMobileDevice)
  {
    window.scrollTo(0, 1);
    store.dispatch(setIsMobile(true));
  }else{
    store.dispatch(setIsMobile(false));
  }
  return (
    <Suspense fallback={<LoadProjectSkeleton/>}>
      <HomeComponent lessThanXSmall={lessThanXSmall} isExternalUser = {isExternalUser} />
    </Suspense>
  );
}
const ConstructionPortalPage = () => (
  <Suspense fallback={<></>}>
    <ConstructionPortal/>
  </Suspense>
);
const ProjectComponentPageForExternalUser = () => {
  const AppConfig = store.getState().config;
  const isExternalUser : boolean = window.location.pathname.split('/')[2] === 'widget' ? true : false;
  const isEsv2User : boolean = window.location.href.includes(AppConfig.isEasyStructure) ? true : false;
  const theme = useTheme();
  const lessThanXSmall = useMediaQuery(theme.breakpoints.down("xs"));

  if(isExternalUser && !isEsv2User){
    return (
      <Suspense fallback={<></>}>
        <WidgetForExternalUser  lessThanXSmall={lessThanXSmall} isExternalUser={isExternalUser} />
      </Suspense>
    );
  }else{
    return (<UnAuthorizedComponentPage />)
  }
}
const UnAuthorizedComponentPage = () => (
  <Suspense fallback={<></>}>
    <UnAuthorizedComponent/>
  </Suspense>
);

const SnapshotImageComponentPage = () => (
  <Suspense fallback={<></>}>
    <SnapshotImageComponent/>
  </Suspense>
);

export {
  ProjectComponentPage,
  HomeComponentPage,
  ConstructionPortalPage,
  UnAuthorizedComponentPage,
  SnapshotImageComponentPage,
  ProjectComponentPageForExternalUser
};
