import React from 'react';
import Skeleton from '@material-ui/lab/Skeleton';
import { Typography, Paper } from '@material-ui/core';
import Colors from '../Colors';
import useStyles from './loadingComponentsCss';
import './SplashScreen.css';

export function SplashScreen() {
  const classes = useStyles();
  return (
    <div
      className={'splashWrapper ' + classes.wrap}
      style={{ color: Colors.Primary }}
    >
      <Skeleton
        style={{
          background:
            'linear-gradient(to right, ' +
            Colors.Primary +
            ', ' +
            Colors.Secondary +
            ')'
        }}
        animation="wave"
      />
      <div className={classes.splashScreenWrapper}>
        <Typography variant="h3" component="h1" color="inherit">
          {'EasyPlant 3D'}
        </Typography>
        <Typography
          variant="h4"
          style={{ margin: '20px 0' }}
          component="h4"
          color="inherit"
        >
          {'Loading...'}
        </Typography>

        <Paper className={classes.paper}>
          <img
            src={`${process.env.PUBLIC_URL}/images/TECHNIP_ENERGIES_LOGO_VERTICAL.svg`}
            className={classes.splashScreenLogo}
            alt="TechnipEnergies logo"
          />
        </Paper>
      </div>
      <div className={classes.copyrightLabel}>
        <span>© Technip Energies {new Date().getFullYear()}</span>
      </div>
    </div>
  );
}
