import { createStore, applyMiddleware, Store } from 'redux';
import rootReducer from './reducers/rootReducer';

let store: Store;

if (process.env.NODE_ENV !== 'production') {
    let logger = require("redux-logger");
    store = createStore(
        rootReducer,
        applyMiddleware(logger.createLogger())
    );
} else {
    store = createStore(rootReducer)
}

export default store;
