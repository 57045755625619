import { v4 as uuid } from 'uuid';
import {
  SELECT_MODELS,
  SET_MODEL_OFFSET,
  SET_VIEWER_INSTANCE,
  ViewerActionType,
  COLOR_OBJETS_MODEL,
  COLOR_BI_TAGS,
  SELECT_TAGS,
  SELECT_TAG_NAMES,
  ISOLATE_TAGS,
  ISOLATE_TAG_NAMES,
  SELECTED_OBJECT_IN_VIEWER,
  OPEN_PANEL,
  OPEN_PANELS,
  CHANGE_POSITION_CAMERA,
  CAMERA_POSITION_CHANGED,
  EXTENSION_ACTIVE_CHANGED,
  SELECT_AGGREGATE_TAGS,
  SELECTED_STATUS_MODES,
  CUTPLANES_CHANGED,
  SELECT_CUTPLANES,
  EDIT_CUTPLANES,
  SET_CUSTOM_ENTITES_COLORED,
  OPEN_BI_REPORT,
  SET_SCREENSHOT_ACTION,
  SET_SCREENSHOT_URL,
  CHANGE_SELECTION_LEVEL,
  SET_MAX_SELECTION_LEVEL,
  CREATE_SNAPSHOT,
  SET_MODEL_PROPERTIES,
  SAVE_VIEWER_SETTINGS,
  APPLY_VIEWER_SETTINGS,
  SET_IS_MOBILE,
  SET_IS_LESSTHANXSMALL,
  SET_PANEL_SCROLL_POSITION,
  UPDATE_OPEN_PANELS,
  CLOSE_PANEL,
  UPDATE_PANEL_POSITION,
  SET_CURRENT_PROJECT
} from '../actionTypes';
import { ViewerState } from '../stateTypes';

const initialState: ViewerState = {
  selectedPlant: null,
  selectedUnits: [],
  selectedStatusModes: [],
  cutplanes: null,
  selectionLevel: 1,
  maxSelectionLevel: 0,
  scrollPosition: 0,
  openPanels: [],
  panelPositions: {},
};

export function viewerReducer(
  state = initialState,
  action: ViewerActionType
): ViewerState {
  switch (action.type) {
    case SELECT_MODELS:
      return Object.assign({}, state, {
        selectedPlant: action.payload.selectedPlant,
        selectedUnits: action.payload.selectedUnits,
        selectedTagInViewer: action.payload.selectedTagInViewer
      });
    case SET_MODEL_OFFSET:
      return Object.assign({}, state, {
        globalOffset: action.payload.globalOffset
      });
    case SET_VIEWER_INSTANCE:
      return Object.assign({}, state, {
        viewerInstance: action.payload.viewerInstance
      });
    case SET_MODEL_PROPERTIES:
      return Object.assign({}, state, {
        properties: action.payload.properties
      });
    case COLOR_OBJETS_MODEL:
      return Object.assign({}, state, {
        entitiesColored: action.payload.entitiesColored,
        selectedEntitiesColoredActionId: uuid()
      });
    case COLOR_BI_TAGS:
      return Object.assign({}, state, {
        coloredBITags: action.payload.coloredBITags,
        coloredBITagsActionId: uuid(),
        multiplePKConfig: action.payload.multiplePKConfig
      });
    case SELECT_TAGS:
      return Object.assign({}, state, {
        selectedTags: action.payload.selectedTags,
        selectedTagsActionId: uuid()
      });
    case SELECT_TAG_NAMES:
      return Object.assign({}, state, {
        selectedTagNames: action.payload.selectedTagNames,
        selectedTagNamesActionId: uuid(),
        selectedCheckbox: action.payload.selectedCheckbox,
        multiplePKConfig: action.payload.multiplePKConfig
      });
    case SELECT_AGGREGATE_TAGS:
      return Object.assign({}, state, {
        selectedAggregateTags: action.payload.selectedAggregateTags
      });
    case ISOLATE_TAGS:
      return Object.assign({}, state, {
        isolatedTags: action.payload.isolatedTags,
        isolatedTagsActionId: uuid(),
        multiplePKConfig: action.payload.multiplePKConfig
      });
    case ISOLATE_TAG_NAMES:
      return Object.assign({}, state, {
        isolatedTagNames: action.payload.isolatedTagNames,
        isolatedTagNamesActionId: uuid()
      });
    case SELECTED_OBJECT_IN_VIEWER:
      return Object.assign({}, state, {
        selectedTagInViewer: action.payload.selectedTagInViewer
      });
    case OPEN_PANEL: {
      const newPanel = action.payload;
      const existingPanels = state.openPanels || [];
      return {
        ...state,
        openPanels: [...existingPanels, newPanel]
      };
    };
    //snapshot copy url with panel open state is not updating the panel state
    case OPEN_PANELS: {
      const newPanels = action.payload;
      return {
        ...state,
        openPanels: newPanels
      };
    };
    case UPDATE_OPEN_PANELS: {
      return {
        ...state,
        openPanels: action.payload,
      };
    }
    case CLOSE_PANEL:
      if (state.openPanels === undefined) return state;
      return {
        ...state,
        openPanels: state.openPanels?.filter(panel => panel.PanelId !== action.payload.panelId)
      };
    case UPDATE_PANEL_POSITION:
      return {
        ...state,
        panelPositions: {
          ...state.panelPositions,
          [action.payload.panelId]: action.payload.position,
        },
      };
    case CAMERA_POSITION_CHANGED:
      return Object.assign({}, state, {
        cameraPosition: action.payload.cameraPosition
      });
    case EXTENSION_ACTIVE_CHANGED:
      return Object.assign({}, state, {
        extension: action.payload.extension
      });
    case CHANGE_POSITION_CAMERA:
      return Object.assign({}, state, {
        changePositionCamera: action.payload.changePositionCamera,
        selectedCameraActionId: uuid()
      });
    case SELECTED_STATUS_MODES:
      return Object.assign({}, state, {
        selectedStatusModes: action.payload.selectedStatusModes
      });
    case SET_CUSTOM_ENTITES_COLORED:
      return Object.assign({}, state, {
        customEntitiesColored: action.payload.customEntitiesColored
      });
    case CUTPLANES_CHANGED:
      return Object.assign({}, state, {
        cutplanes: action.payload.cutplanes
      });
    case SELECT_CUTPLANES:
      return Object.assign({}, state, {
        selectedVolume: action.payload.selectedVolume,
        selectedVolumeActionId: uuid()
      });
    case EDIT_CUTPLANES:
      return Object.assign({}, state, {
        editedVolume: action.payload.editedVolume,
        editedVolumeActionId: uuid()
      });
    case OPEN_BI_REPORT:
      return Object.assign({}, state, {
        openedBIReport: action.payload.openedBIReport
      });
    case SET_SCREENSHOT_ACTION:
      return Object.assign({}, state, {
        screenshotAction: action.payload.screenshotAction,
        screenshotActionId: uuid()
      });
    case SET_SCREENSHOT_URL:
      return Object.assign({}, state, {
        screenshotUrl: action.payload.screenshotUrl
      });
    case CHANGE_SELECTION_LEVEL:
      return Object.assign({}, state, {
        selectionLevel: action.payload.selectionLevel
      });
    case SET_MAX_SELECTION_LEVEL:
      return Object.assign({}, state, {
        maxSelectionLevel: action.payload.maxSelectionLevel
      });
    case CREATE_SNAPSHOT:
      return Object.assign({}, state, {
        createdSnapshotId: action.payload.createdSnapshotId
      });
    case SAVE_VIEWER_SETTINGS:
      return Object.assign({}, state, {
        viewerSettings: action.payload.viewerSettings
      });
    case APPLY_VIEWER_SETTINGS:
      return Object.assign({}, state, {
        applyViewerSettings: action.payload.applyViewerSettings
      });
    case SET_IS_MOBILE:
      return Object.assign({}, state, {
        isMobile: action.payload.isMobile
      });
    case SET_IS_LESSTHANXSMALL:
      return Object.assign({}, state, {
        isLessThanXSmall: action.payload.isLessThanXSmall
      });
    case SET_PANEL_SCROLL_POSITION:
      const containerId = typeof action.payload.containerId === 'string' ? action.payload.containerId : 'default';
      return Object.assign({}, state, {
        scrollPosition: Object.assign({}, state.scrollPosition, {
          [containerId]: action.payload.scrollPosition,
        }),
      });

    default:
      return state;
  }
}
