import { v4 as uuid } from 'uuid';
import { SET_STEP_INDEX, SET_RUN, SET_IS_MANUAL_INCREMENT, SET_IDENTIFIER, TourActionType } from '../actionTypes';
import { ITourState } from '../stateTypes';

const initialState: ITourState = {
  stepIndex: 0,
};

export function tourReducer(
  state = initialState,
  action: TourActionType
): ITourState {
  if (action.type === SET_STEP_INDEX) {
    return Object.assign({}, state, {
      stepIndex: action.payload.stepIndex,
    });
  }
  if (action.type === SET_RUN) {
    return Object.assign({}, state, {
      run: action.payload.run,
    });
  }
  if (action.type === SET_IS_MANUAL_INCREMENT) {
    return Object.assign({}, state, {
      isManualIncrement: action.payload.isManualIncrement,
    });
  }
  if (action.type === SET_IDENTIFIER) {
    return Object.assign({}, state, {
      identifier: action.payload.identifier,
    });
  }
  return state;
}
