import React from 'react';
import Skeleton from '@material-ui/lab/Skeleton';
import {
  Typography,
  Divider
} from '@material-ui/core';
import './LoadSkeleton.css';

interface IProps {
  name: string;
}

export function LoadSkeleton(props: IProps) {
  //setting dynamic number of skeleton loaders
  let element: any = document.querySelector(`#${props.name}`);
  let height = element ? element.style.height : 280;
  let counter = Math.ceil(height / 48);

  return (
    <div style={{ margin: '5px' }}>
      {[...Array(counter)].map((elem, index) => {
        return (
          <Skeleton
            style={{ height: '48px', width: '99%' }}
            animation='wave'
            key={index}
          />
        );
      })}
    </div>
  );
}

export function LoadPanelSkeleton(props: IProps) {
  //setting dynamic number of skeleton loaders
  let element: any = document.querySelector(`#${props.name}`);
  let height = element ? element.style.height : 515;
  let counter = Math.ceil(height / 48);

  return (
    <div style={{ margin: '5px' }}>
      <div style={{ display: 'flex', height: '48px' }}>
        <Skeleton style={{ height: '48px', width: '30%' }} animation='wave' />
        <Skeleton
          style={{
            height: '48px',
            width: '15%',
            position: 'absolute',
            right: '10px'
          }}
          animation='wave'
        />
        <Skeleton
          style={{
            height: '48px',
            width: '15%',
            position: 'absolute',
            right: '20%'
          }}
          animation='wave'
        />
      </div>
      {[...Array(counter)].map((elem, index) => {
        return (
          <Skeleton
            style={{ height: '48px', width: '99%' }}
            animation='wave'
            key={index}
          />
        );
      })}
    </div>
  );
}
export function LoadProjectSkeleton() {
  return (
    <div className="projectSkeletonMain">
      <div className="projectSkeletonHeader">
        <div className="main-header">
          <Skeleton animation="wave" height={50} width="100px" />
          <div className="header-title-container">
            <Typography
              variant="h6"
              component="h1"
              color="primary"
              style={{ lineHeight: 1.25 }}
            >
              <Skeleton
                animation="wave"
                height={28}
                width="190px"
                style={{ marginBottom: '7px' }}
              />
            </Typography>
            <Typography variant="caption">
              <Skeleton animation="wave" height={15} width="120px" />
            </Typography>
          </div>
        </div>
        <div>
          <Skeleton
            animation="wave"
            variant="circle"
            width={45}
            height={45}
            style={{ marginRight: '10px' }}
          />
        </div>
      </div>
      <Divider style={{ borderRightWidth: 5 }} />
      <div className="projectSkeletonContainer">
        <div className="SkeletonContainer1">
          <Skeleton animation="wave" variant="circle" width={45} height={45} />
          <Skeleton animation="wave" variant="circle" width={45} height={45} />
        </div>
        <Divider style={{ borderRightWidth: 5 }} orientation="vertical" />
        <div className="SkeletonContainer2">
          <Skeleton animation="wave" height={28} width="100px" style={{ marginBottom: '0px' }}/>
          <Skeleton animation="wave" height={28} width="250px" style={{ marginTop: '10px',marginBottom:'20px' }} />
          <Skeleton animation="wave" height={28} width="100px" style={{ marginBottom: '0px' }}/>
          <Skeleton animation="wave" height={38} width="250px" style={{ marginTop: '10px' }} />
          {[...Array(12)].map((elem, index) => {
            return <Skeleton animation="wave" height={50} width="250px" />;
          })}
          <div className='skeletonArrow'>
            <Skeleton animation="wave"  height={38} width={38} />
          </div>
        </div>
        <Divider style={{ borderRightWidth: 5 }} orientation="vertical" />
        <div className="skeletonContainer3"></div>
      </div>
    </div>
  );
}
